import moment from "moment";
import {
  findFeeOrderCountByItem,
  getOrderDetail,
  orderServiceFeeSignQty,
  payInfo,
  sapPay,
  selectByCode
} from "@/views/serviceFeeModule/serviceDetail/api";
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { mapState } from "vuex";
import { queryYue } from "@/views/serviceFeeModule/payOrderPage/api";

export default {
  name: "",
  components: {

  },
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/serviceFeeModule/serviceFreeList",
          name: "order",
          title: "服务费单据"
        },
        {
          path: "/serviceFeeModule/payDetail",
          name: "detail",
          title: "单据详情"
        }
      ],
      orderDetail: {},
      pageLoadFlag: false,
      orderList:[],
      showDisplay:false,
      productArray:[],
      detailInfo:{},
      ordersList:[],
      orderIds:[],
      checkId: '', // 支付方式默认为空
      sapMoney: 0, // 余额
      amount: 0, // 待支付服务费
      detail: {}, // 详细信息
      type:'15691143853',
      time:'',
      agreeBtn:false,
      isLoading:false,
      invoiceType:[],
      remainingTime:'',
      expireDate:'',
      releationOrderList:[],
      isDMS:false

    };
  },
  computed:{
    totalProd:function (){
      let total = 0
      if(this.productArray&&this.productArray.length>0){
        this.productArray.forEach(item=>{
          total+=item.qty
        })
      }
      return parseInt(total||0)

    },
    totalLoadVolume:function (){
      let total = 0
      if(this.productArray&&this.productArray.length>0){
        this.productArray.forEach(item=>{
          total+=item.qty*Math.max(item.volume,0)
        })
      }
      return total.toFixed(3)
    },
    ...mapState({
      productImgBaseUrl: state =>
        state.user.productImgBaseUrl
          ? state.user.productImgBaseUrl
          : localStorage.getItem("productImgBaseUrl")
    }),
    testList() {
      // 当数据不需要完全显示的时候
      if(this.showDisplay == false){
        let testList = []; // 定义一个空数组
        // 先显示前三个
        if(this.ordersList.length > 1){
          for(var i = 0;i < 1;i++){
            testList.push(this.ordersList[i])
          }
        }else{
          testList = this.ordersList;
        }
        return testList; // 返回当前数组
      }
      else{//展示全部数据
        return this.ordersList;
      }
    },
    testListDMS(){
      // 当数据不需要完全显示的时候
      if(this.showDisplay == false){
        let testListDMS = []; // 定义一个空数组
        // 先显示前三个
        if(this.releationOrderList.length > 1){
          for(var i = 0;i < 1;i++){
            testListDMS.push(this.releationOrderList[i])
          }
        }else{
          testListDMS = this.releationOrderList;
        }
        return testListDMS; // 返回当前数组
      }
      else{//展示全部数据
        return this.releationOrderList;
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)

    } else {
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
    this.getInvoiceType()

  },
  methods: {
    //查看协议
    gotoXY(){
      this.$router.push({
        path: "/serviceFeeModule/logisicsAgreent",
      });
    },
    timestampToTime(timestamp) {
      if (timestamp != null) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y + M + D + h + m + s;
      } else {
        return '';
      }

    },

    gotoDmsOrder(item,index){
      let id = item.documentId
      let url = ''
      if(item.documentNum.indexOf('P') > -1) {
        url = '/channelOrder/detail' // 渠道订单
      }else if(item.documentNum.indexOf('S') > -1) {
        if(item.documentType == 'retail') { // 零售订单
          url = '/retailOrder/detailRetail'
        }else if(item.documentType == 'normal') { // 分销采购订单
          url = 'retailOrder/detailFX'
        }
      }
      this.$router.push({
        path: url,
        query: { id: id}
      });
      return
    },
    gotoOrder(item,index){
      if(item.includes('BHO')) {
        this.$router.push({
          path: "/order/detail",
          query: { id: this.orderIds[index] }
        });
        return
      }
    },
    getYe(){
      let data={
        feeCodeList: [this.detailInfo.feeCode]
      }
      queryYue(data).then(res=>{
        this.sapMoney = res.data.msg.canUseMoney
      })
    },
    // 获取发票类型
    getInvoiceType() {
      let data = {
        pid: 14963960652
      };
      focusonremarkplz(data).then(res => {
        this.invoiceType = res.data.list;
      });
    },
    agree(e){
      this.agreeBtn = e.target.checked
      this.$forceUpdate()
    },
    changePay(num){
      this.checkId = num
    },
    goPay(){
      if(!this.agreeBtn){
        this.$message.warning('请阅读并同意 《物流服务协议》')
        return
      }
      if(!this.type){
        this.$message.warning('请选择发票类型')
        return
      }
      if(!this.checkId){
        this.$message.warning('请选择支付方式')
        return
      }
      if(this.checkId == 1){
        this.isLoading = true
        let  data = {
          feeCodeList:[this.$route.query.id],
          invoiceType:this.type
        }
        sapPay(data).then(res=>{
          if(res.data.code == 0){
            this.$message.success('付款成功')
            // this.$router.go(-1)
            if(this.$route.query.type == 'list'){
              this.$router.push({
                path: "/serviceFeeModule/servicePaySuccess",
                type:'list'
              });
              this.isLoading = false
              return
            }else{
              this.$router.push({
                path: "/confirm/confirmsuccess",
                query:{
                  b2bOrderCode:this.$route.query.b2bOrderCode,
                  channel: this.$route.query.channel?1:'',
                  orgName:this.$route.query.orgName,
                }
              });
              this.isLoading = false
              return
            }
          }else {
            this.$message.warning(res.data.msg)
            this.isLoading = false
          }
        }).catch(error=>{
          this.isLoading = false
        })
      } else {
        this.isLoading = true
        let  data = {
          feeCodeList:[this.$route.query.id],
          statusName:this.$router.type == 'list'?'list':'',
          invoiceType:this.type
        }
        payInfo(data).then(res=>{
          if(res.data.code == 1){
            this.$message.warning(res.data.msg)
            return
          }
          if(res.data.code == 0){
            // 如果接口返回参数payType并且为unionPay 直接打开该页面去电子收银台页面
            if(res.data.data.payType && res.data.data.payType=='unionPay' ) {
              window.location.href=res.data.data.weCharUrl;
              this.isLoading = false
              return
            }
            this.isLoading = false
          }else {
            this.$message.success(res.data.data.errMsg)
            this.isLoading = false
          }
        }).catch(error=>{
          this.isLoading = false
        })
      }
    },
    changtype(){

    },
    gotoParentFeeCode(item){
      let routeUrl = this.$router.resolve({
        path: "/serviceFeeModule/serviceDetail",
        query: {
          id:item
        }
      });
      window.open(routeUrl.href, "_blank");
      return

    },
    // 获取支付信息
    getInfo() {
      this.pageLoadFlag = true
      let data =  {
        code:this.$route.query.id,
        batch:0
      }
      selectByCode(data).then(res=>{
        if(res.data.code == 0 && res.data.data.amountDetails && res.data.data.amountDetails.length > 0) {
          res.data.data.amountDetails.forEach((el,elIndex) => {
            this.productArray.forEach((item,index)=>{
              if(item.productId == el.productId && elIndex ==index ){
                  this.productArray[index] = {
                    ...this.productArray[index],
                    ...el,
                  }
              }
            })
          })
        }
        const nowDate = new Date()
        this.expireDate =  res.data.data.expireDate
        this.countTime()
        this.amount = res.data.data.amount
        this.detail = res.data.data;
        this.pageLoadFlag = false

      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    // 倒计时
    countTime: function () {
      // 获取当前时间
      let  date = new Date();
      let  now = date.getTime();
      //设置截止时间
      // var endDate = new Date("2020-1-22 23:23:23");
      var end = this.expireDate;
      //时间差
      var leftTime = end - now;
      //定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        this.d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        this.h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        this.m = Math.floor(leftTime / 1000 / 60 % 60) < 10?'0'+ Math.floor(leftTime / 1000 / 60 % 60):Math.floor(leftTime / 1000 / 60 % 60);
        this.s = Math.floor(leftTime / 1000 % 60)< 10?'0'+ Math.floor(leftTime / 1000 % 60):Math.floor(leftTime / 1000 % 60);
        this.sum_h = this.d * 24 + this.h
      }
      //递归每秒调用countTime方法，显示动态时间效果
      // setTimeout(this.countTime, 1000);
    },
    // 查看商品信息差详情
   async getDetail(id){
      getOrderDetail({feeCode:id}).then(res=>{
          if (res.data.code == 0)   {
            this.productArray = res.data.data
            this.detailInfo =  res.data.main
            this.$forceUpdate()
            this.getYe()
            this.orderIds = res.data && res.data.main.relationOrderId?res.data.main.relationOrderId.split(','):[]
            this.ordersList = res.data&&res.data.main.relationOrderCode?res.data.main.relationOrderCode.split(','):[]
            this.getInfo()
            if(this.detailInfo.relationOrderCode.indexOf('BHO') > -1) { // CIS订单
              this.isDMS = false
                let itemIds = this.productArray.map(el => el.salesOrderItemId)
              this.getServiceFeeSignQty(itemIds) // 根据订单明细id查询拒收签收取消数量-海信订单
            }else {
              this.isDMS = true
              let itemIds = this.productArray.map(el => el.dmsIdS)
              this.getServiceFeeSignQtyDms(itemIds) // 根据订单明细id查询拒收签收取消数量-渠道、销售
            }

          } else {
            this.$message.warning(res.data.msg)
          }
       })
    },
    // 根据订单明细id查询拒收签收取消数量-海信订单
    getServiceFeeSignQty(itemIds) {
      let data = {
        itemIds: itemIds.join(',')
      }
      orderServiceFeeSignQty(data).then(res=>{
        if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
          res.data.list.forEach(el => {
            const index = this.productArray.findIndex(it => it.salesOrderItemId == el.itemId)
            if(index > -1){
              this.productArray[index] = {
                ...this.productArray[index],
                ...el,

              }
              this.$nextTick(()=> {
                this.$set( this.productArray,index,this.productArray[index])

              })
            }
          })
        }
      })
    },
    // 根据订单明细id查询拒收签收取消数量-渠道、销售
    getServiceFeeSignQtyDms(itemIds) {
      let data = {
        itemsIds:itemIds.join(',')
      }
      findFeeOrderCountByItem(data).then(res=>{
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        if(data.code == 0 && data && data.data.length > 0) {
          const list = []
          data.data.forEach(el => {
            const index =  this.productArray.findIndex(it => it.dmsIdS == el.dmsIdS)
            const orderIndex = list.findIndex(it => it.documentNum == el.documentNum)
            if(index > -1){
              this.productArray[index] = {
                ...this.productArray[index],
                ...el,
                productPrice: el.backnowledgePrice
              }

            }
            if(orderIndex == -1) {
              list.push(el)
            }
            this.releationOrderList = list
            this.$nextTick(()=> {
              this.$set( this.productArray,index,this.productArray[index])
            })
          })
        }else {
          this.releationOrderList = []
        }
      })
    },
    formatPrice(price) {
      // if (Math.floor(price) === price) {
      //   return price
      // } else {
      return (Math.round(new Number(price) * 1000) / 1000).toFixed(2)
      // }
    },
    getPrice(item) {

    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    goBack() {
      this.$router.go(-1);
      window.close()
    },


  }
};